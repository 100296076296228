import {
  ReactNode, useEffect, useMemo,
} from 'react';
import { Area } from '../utils/createArea';
import { createModal } from '../utils/createModal';

interface Props {
  area: Area;
  children?: ReactNode;
  onComplete?: <TValue, TErr = any>(err: TErr, value?: TValue) => void;
}

export function Modal(props: Props): null {
  const modal = useMemo(() => createModal(props.area), [props.area]);

  useEffect(() => {
    modal.show(props.children).then((val) => props.onComplete?.(null, val)).catch(props.onComplete);
  }, [props.children, modal]);

  useEffect(() => () => {
    modal.close();
  }, [modal]);

  return null;
}
