import { createElement, ReactNode } from 'react';
import { getId } from './getId';
import { eventEmitter } from './eventEmitter';
import { Area } from './createArea';
import { BlockCheckerFn, Context, ModalContext } from '../Modal/context';

export type CompleteFn<T> = (value: T | PromiseLike<T>) => Promise<void>;
export type ErrorFn = (err: any) => void;
export type ShowFn<T> = (resolve: CompleteFn<T>, reject: ErrorFn) => ReactNode;

export function createModal(area: Area) {
  const id = getId();
  const emitter = eventEmitter();
  const blockQueue: BlockCheckerFn[] = [];
  const ctx = {
    area,
    subscribe: emitter.subscribe,
    block: (fn) => {
      blockQueue.push(fn);
      return () => {
        const index = blockQueue.indexOf(fn);
        if (index > -1) blockQueue.splice(index, 1);
      };
    },
  } satisfies Partial<Context>;

  let isActive = false;
  let version = 0;

  const checkBlockers = async () => {
    const blockers = await Promise.all(blockQueue.map((fn) => fn()));
    return blockers.every(Boolean);
  };

  return {
    show: <T = void>(nodeOrFn: ReactNode | ShowFn<T>) => {
      isActive = true;
      version += 1;

      return new Promise<T>((res, rej) => {
        const resolve: CompleteFn<T> = async (val) => {
          if (!await checkBlockers()) return;
          return res(val);
        };

        area.upsert({
          id,
          element: createElement(ModalContext.Provider, {
            children: typeof nodeOrFn === 'function' ? nodeOrFn(resolve, rej) : nodeOrFn,
            value: {
              ...ctx,
              success: resolve,
              error: rej,
            },
          }),
        });
      });
    },

    get isActive() {
      return isActive;
    },

    checkInterlocks() {
      return checkBlockers();
    },

    async close() {
      const curVersion = version;
      await emitter.fire();
      if (curVersion !== version) return;

      area.remove(id);
      isActive = false;
    },
  };
}
