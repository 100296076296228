import { createContext } from 'react';
import { Area } from '../utils/createArea';
import { EventEmitterResult } from '../utils/eventEmitter';
import { CompleteFn, ErrorFn } from '../utils/createModal';

export type BlockCheckerFn = () => Promise<boolean> | boolean;

export interface Context {
  area: Area;
  subscribe: EventEmitterResult['subscribe'];
  block: (checker: BlockCheckerFn) => () => void;
  success: CompleteFn<any>;
  error: ErrorFn;
}

export const ModalContext = createContext<Context | null>(null);
