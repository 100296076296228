type Fn = () => void;
export type EventEmitterResult = ReturnType<typeof eventEmitter>;

export const eventEmitter = () => {
  const events: Fn[] = [];

  return {
    subscribe: (fn: Fn) => {
      events.push(fn);

      return () => {
        const index = events.indexOf(fn);
        if (index > -1) events.splice(index, 1);
      };
    },
    fire: async () => {
      const promises = events.map((e) => e());
      events.length = 0;
      await Promise.all(promises);
    },
  };
};
