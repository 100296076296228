import { useEffect } from 'react';
import { useModalContext } from './useModalContext';

type BlockModalChecker = () => Promise<boolean> | boolean;
interface UseBlockModal {
  skip?: boolean;
  checker: BlockModalChecker;
}

export const useBlockModal = (props: UseBlockModal | BlockModalChecker) => {
  const ctx = useModalContext();
  const config = typeof props === 'object' ? props : {
    skip: false,
    checker: props,
  };

  useEffect(() => {
    if (config.skip || !ctx) return;
    return ctx.block(config.checker);
  }, [config.skip, config.checker]);
};
