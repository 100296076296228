import { useEffect, useState } from 'react';

import { delay } from '../utils/delay';
import { useModalContext } from './useModalContext';

export const useFlowValue = <
  I extends any,
  L extends any,
  U extends any,
>(time: number, initial: I, live: L, unloaded: U) => {
  const [value, setValue] = useState<I | L | U>(initial);
  const ctx = useModalContext();

  useEffect(() => {
    if (!ctx) return;

    type Fn = () => void;
    const clear: Fn[] = [];

    if ((live as any) !== (initial as any)) {
      const id = setTimeout(() => {
        setValue(live);
      }, 100);

      clear.push(() => clearTimeout(id));
    }

    if ((live as any) !== (unloaded as any)) {
      const sub = async () => {
        setValue(unloaded);
        await delay(time);
      };

      clear.push(ctx.subscribe(sub));
    }

    return () => clear.forEach((fn) => fn());
  }, []);

  return value;
};
