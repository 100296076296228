import { State } from '../ModalsArea/state';

interface IController {
  upsert: (state: State) => void;
  remove: (id: string) => void;
}

export interface Area extends IController {
  attach: (id: string, controller: IController) => void;
  detach: () => void;
}

export const createArea = (): Area => {
  let id: string | undefined;
  const queue: State[] = [];
  let controller: IController | undefined;

  return ({
    attach(nextId, nextController) {
      if (id && id !== nextId) {
        throw new Error('Cannot use one Area for two or more ModalsArea');
      }

      id = nextId;
      controller = nextController;

      queue.forEach(controller.upsert.bind(controller));
      queue.length = 0;
    },
    detach() {
      id = undefined;
      controller = undefined;
    },
    upsert(state) {
      if (controller) {
        return controller.upsert(state);
      }

      queue.push(state);
    },
    remove(id) {
      if (controller) {
        return controller.remove(id);
      }

      const index = queue.findIndex((state) => state.id === id);
      if (index >= 0) queue.splice(index, 1);
    },
  });
};
