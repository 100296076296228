import React, { Fragment, useEffect, useRef } from 'react';
import { useRequestReload } from '../hooks/useRequestReload';
import { useId } from '../hooks/useId';
import { State } from './state';
import { Area } from '../utils/createArea';

interface Props {
  area: Area;
}

type List = State[];
type FilterFn = (arr: List) => List;

export function ModalsArea(props: Props) {
  const { area } = props;
  const id = useId();
  const requestReload = useRequestReload();
  const modalsRef = useRef<List>([]);
  const modals = modalsRef.current;

  useEffect(() => {
    const setModals = (fn: FilterFn) => {
      modalsRef.current = fn(modalsRef.current);
      requestReload();
    };

    const onUpsert = (state: State) => {
      setModals((arr) => {
        const index = arr.findIndex((modal) => modal.id === state.id);

        if (index > -1) {
          const cloned = [...arr];
          cloned.splice(index, 1, state);
          return cloned;
        }
        return [...arr, state];
      });
    };

    const onDelete = (id: string) => {
      setModals((arr) => arr.filter((m) => m.id !== id));
    };

    area.attach(id, {
      upsert: onUpsert,
      remove: onDelete,
    });

    return () => {
      area.detach();
    };
  }, [area, modalsRef, requestReload, id]);

  return (
    <>
      {modals.map((modal) => (
        <Fragment key={modal.id}>{modal.element}</Fragment>
      ))}
    </>
  );
}
