import { ReactNode } from 'react';
import { createModal, ShowFn } from './createModal';
import { Area } from './createArea';

export async function showModal<T = void>(area: Area, nodeOrFn: ReactNode | ShowFn<T>) {
  const modal = createModal(area);

  try {
    return await modal.show<T>(nodeOrFn);
  } finally {
    await modal.close();
  }
}
