import { useCallback, useRef, useState } from 'react';

export const useRequestReload = () => {
  const [, update] = useState(0);
  const requested = useRef(false);

  requested.current = false;

  return useCallback(() => {
    if (requested.current) return;

    requested.current = true;

    setTimeout(() => update((i) => i + 1));
  }, [update, requested]);
};
