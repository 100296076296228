import { useModalContext } from './useModalContext';
import { CompleteFn, ErrorFn } from '../utils/createModal';

export const useCompleteModal = <T = unknown>() => {
  const ctx = useModalContext();

  if (!ctx) throw new Error('Unable to use useCompleteModal outside <Modal /> context');

  const success = ctx.success as CompleteFn<T>;
  const error = ctx.error as ErrorFn;

  return { success, error };
};
